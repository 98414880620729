/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: 92%;
}
#home {
  margin-right: 2%;
}
#head {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
.cb-layout2 #head,
.cb-layout3 #head,
.cb-layout4 #head,
.cb-layout5 #head {
  margin: 30px 2%;
  width: 96%;
}
.toplinks {
  margin-left: 2%;
}
.copyright,
.service_share {
  margin-left: 2%;
}
.toplink {
  margin-right: 2%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 21%;
}
.area > .slim {
  width: 25%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 8%;
  margin-left: 8%;
}
.area > .slim .part {
  width: 84%;
}
.area > .slim .tiny {
  width: 84%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout2 .main .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout2 .main .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout2 .main .tiny {
  width: 46%;
}
.cb-layout2 .main > .slim {
  width: 48%;
}
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part {
  margin-right: 4.08333333%;
  margin-left: 4.08333333%;
}
.cb-layout2 .main > .slim .part {
  width: 91.83333333%;
}
.cb-layout2 .main > .slim .tiny {
  width: 91.83333333%;
}
.cb-layout3 .main {
  width: 100%;
}
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .main .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout3 .main .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout3 .main .tiny {
  width: 46%;
}
.cb-layout3 .main > .slim {
  width: 50%;
}
.cb-layout3 .main > .slim .foot,
.cb-layout3 .main > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
}
.cb-layout3 .main > .slim .part {
  width: 92%;
}
.cb-layout3 .main > .slim .tiny {
  width: 42%;
}
.cb-layout4 .main {
  width: 100%;
}
.cb-layout4 .main > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout4 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout4 .main .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout4 .main > .slim {
  width: 46%;
}
.cb-layout4 .main > .slim .foot,
.cb-layout4 .main > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout4 .main > .slim .part {
  width: 100%;
}
.cb-layout4 .main > .slim .tiny {
  width: 100%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.south .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.south .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.south .tiny {
  width: 46%;
}
.south > .slim {
  width: 48%;
}
.south > .slim .foot,
.south > .slim .part {
  margin-right: 4.08333333%;
  margin-left: 4.08333333%;
}
.south > .slim .part {
  width: 91.83333333%;
}
.south > .slim .tiny {
  width: 91.83333333%;
}
.cb-layout5 .main {
  width: 100%;
}
.cb-layout5 .main > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout5 .main .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout5 .main .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout5 .main .tiny {
  width: 46%;
}
.cb-layout5 .main > .slim {
  width: 48%;
}
.cb-layout5 .main > .slim .foot,
.cb-layout5 .main > .slim .part {
  margin-right: 4.08333333%;
  margin-left: 4.08333333%;
}
.cb-layout5 .main > .slim .part {
  width: 91.83333333%;
}
.cb-layout5 .main > .slim .tiny {
  width: 91.83333333%;
}
.cb-layout2 div.main {
  width: 102%;
  margin-left: -1%;
}
.cb-layout5 div.main {
  width: 102%;
  margin-left: -1%;
}
div.south {
  width: 102%;
  margin-left: -1%;
}
.filter-list {
  width: 102%;
  margin-left: -1%;
  margin-top: -6px;
  margin-bottom: -6px;
}
.filter-item {
  margin: 6px 1%;
  width: 48%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */